<template>
    <div class="programs-projects-landing">
        <div class="hero page-heading">
            <HeroBasic
                :heading="title"
                accentColor="grey"
                class="hero-wrapper"
                :showTopographic="true"
                :hasHeaderOffset="true"
            />

            <PageControls class="subnav-wrapper" :noBorder="true">
                <template #left>
                    <div class="subnav">
                        <Anchor
                            v-if="sortedPrograms.length > 0"
                            class="link"
                            :class="{ active: 0 === activeIndex }"
                            @click="clickHandler(0, 'programs')"
                            :key="`anchor-0`"
                            >Programs</Anchor
                        >
                        <Anchor
                            v-if="sortedProjects.length > 0"
                            class="link"
                            :class="{ active: 1 === activeIndex }"
                            @click="clickHandler(1, 'projects')"
                            :key="`anchor-1`"
                            >Projects</Anchor
                        >
                    </div>
                </template>
            </PageControls>
        </div>

        <div class="page-body">
            <div class="programs-projects-list">
                <template v-if="activeIndex === 0">
                    <ProgramProjectListItem
                        v-for="(item, index) in sortedPrograms"
                        :key="index"
                        :title="item.title"
                        titleVariant="h3-display"
                        :url="item.path"
                        :dek="item.summary.dek"
                        :contributors="item.summary.contributors"
                        class="list-item"
                        :pageLanguage="pageLanguage"
                    />
                </template>

                <template v-else-if="activeIndex === 1">
                    <div v-if="isDC">
                        <template v-for="(program, programName, programIndex) in sortedProjectsByProgram">
                            <div
                                :key="programIndex"
                                v-if="program.length"
                                class="program-wrapper list-item"
                                :programName="programIndex"
                            >
                                <div class="program-heading">
                                    <Typography
                                        as="div"
                                        variant="body-display-large-italic"
                                        class="inner"
                                        v-if="programName !== 'other'"
                                    >
                                        {{ programName }}
                                    </Typography>
                                </div>

                                <ProgramProjectListItem
                                    v-for="(project, projectIndex) in program"
                                    :key="project.title"
                                    :programName="projectIndex"
                                    :title="project.title"
                                    titleVariant="h4-display"
                                    :url="project.path"
                                    :dek="project.summary.dek"
                                    :contributors="project.summary.contributors"
                                    :thinPadding="true"
                                    :pageLanguage="pageLanguage"
                                />
                            </div>
                        </template>
                    </div>
                    <template v-else>
                        <ProgramProjectListItem
                            v-for="(project, projectIndex) in sortedProjects"
                            :programName="projectIndex"
                            :key="project.title"
                            :title="project.title"
                            titleVariant="h4-display"
                            :url="project.path"
                            :dek="project.summary.dek"
                            :contributors="project.summary.contributors"
                            :pageLanguage="pageLanguage"
                        />
                        <div class="program-heading">
                            <Typography
                                as="div"
                                variant="body-display-large-italic"
                                class="inner"
                                v-if="sortedCollections?.length"
                            >
                                {{ $t('Collections') }}
                            </Typography>
                        </div>
                        <ProgramProjectListItem
                            v-for="(collection, collectionIndex) in sortedCollections"
                            :programName="collectionIndex"
                            :key="collection.title"
                            :title="collection.title"
                            titleVariant="h4-display"
                            :url="collection.path"
                            :dek="collection.summary.dek"
                            :contributors="collection.summary.contributors"
                            :pageLanguage="pageLanguage"
                        />
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup>
import sortBy from 'lodash/sortBy';
const { currentCenter } = useCenters();
const { pageLanguage } = usePageDataStore();
const props = defineProps({
    title: {
        type: String,
    },
    contentType: {
        type: String,
    },
    programsTabLabel: {
        type: String,
    },
    projectsTabLabel: {
        type: String,
    },
    path: {
        type: String,
    },
    locale: {
        type: String,
        default: '',
    },
});

const programsData = ref([]);
const projectsData = ref([]);
const collectionsData = ref([]);
let activeIndex = ref(0);

let isDC = false;
if (currentCenter.value.slug === 'global') {
    isDC = true;
}
if (!isDC) {
    activeIndex = ref(1);
}

const clickHandler = (index) => {
    activeIndex.value = index;
};

const params = (contentType) => {
    return {
        collection: props.contentType,
        filters: {
            types: contentType,
        },
        limit: 100,
    };
};

programsData.value = await useContentIndexData(params('programs'));
projectsData.value = await useContentIndexData(params('projects'));
collectionsData.value = await useContentIndexData(params('collections'));

const sortedPrograms = computed(() => {
    return sortBy(Array.from(programsData.value.docs), 'title');
});
// POST LAUNCH FIXME revisit this post launch to make it cleaner instead of having a DC and non DC version for this data
const sortedProjects = computed(() => {
    return sortBy(Array.from(projectsData.value.docs), 'title');
});

const sortedCollections = computed(() => {
    const finalList = [];
    const alphaList = sortBy(Array.from(collectionsData.value.docs), 'title');
    for (const item in alphaList) {
        if (alphaList[item].summary.syndications.blogs.length === 0) {
            finalList.push(alphaList[item]);
        }
    }
    return finalList;
});

const sortedProjectsByProgram = computed(() => {
    const allPrograms = {};
    const otherProjects = [];

    sortBy(Array.from(programsData.value.docs), 'title').forEach((program) => {
        allPrograms[program.title] = [];
    });

    Array.from(projectsData.value.docs).forEach((project) => {
        if (project.summary?.syndications?.programs?.length) {
            project.summary?.syndications?.programs?.forEach((program) => {
                Object.entries(allPrograms).forEach(([programName]) => {
                    if (programName === program.title) {
                        allPrograms[programName].push(project);
                    }
                });
            });
        } else {
            otherProjects.push(project);
        }
    });

    allPrograms.other = otherProjects;

    return allPrograms;
});

const headerAttributesStore = useHeaderAttributesStore();

const GTMCenter = useGTM();
const GTMLang = props.locale;
onMounted(() => {
    window.dataLayer.push({
        Center: GTMCenter.GTMCenter,
        Doctype: 'Programs Projects Landing',
        Expert: '',
        'Taxonomy Tags Program': '',
        'Taxonomy Special Project': '',
        'Taxonomy Tags Regions': '',
        'Taxonomy Tags Topics': '',
        'Page Language': GTMLang,
        event: 'new_data_layer',
    });
    headerAttributesStore.updateHeaderStyleAttributes({ background: 'transparent' });
});
</script>

<style lang="scss" scoped>
.page-heading {
    overflow: hidden;
    position: relative;
    background: color(grey, light);
    border-bottom: 0.1rem solid palette(border-light);
}

.hero-wrapper {
    overflow: visible;
}

.subnav-wrapper {
    @include content-section;
}

.subnav {
    @include content-padding;
    display: flex;
    gap: 2rem;
    justify-content: flex-start;

    @include media-query(phone) {
        text-wrap: nowrap;
        overflow: scroll;
        position: relative;
    }
}

.link {
    color: color(blue, dark, 0.6);
    @include transition(color, controls);

    &.active {
        color: color(blue, dark);
    }

    &:hover {
        color: color(teal, dark);
    }
}

.list-item {
    padding-top: vertical-space(3);
    padding-bottom: vertical-space(1);

    &:not(:last-of-type) {
        border-bottom: $border;
    }
}

.program-heading {
    @include content-section;

    color: color(noir, default, 0.6);
    padding-bottom: vertical-space(1);

    @include media-query(not-phone) {
        padding-inline-start: var(--col-gutter);
    }

    .inner {
        @include media-query(not-phone) {
            @include content-section;
        }
    }
}
</style>
